'use client'

import type { DataUpdatedTypes } from '../../../../../lib/constants/lib'

export const WebsocketService = Object.freeze({
  subscribe: (token: string, onMessage?: (dataUpdated: DataUpdatedTypes, data: unknown) => Promise<void>): { destroy: () => void } => {
    let ws: WebSocket | null = null
    let keepAliveId: number | null = null
    let reconnectId: number | null = null

    const CLOSE_DESTROY = 3000
    const CLOSE_RECONNECT = 3001

    const openConnection = (): void => {
      if (!process.env['NEXT_PUBLIC_PICTRIX_WEBSOCKET_API_URL']) {
        return
      }

      try {
        ws = new WebSocket(process.env['NEXT_PUBLIC_PICTRIX_WEBSOCKET_API_URL'] ?? '', token)
      } catch (error) {
        setTimeout(() => {
          openConnection()
        }, 1000)

        return
      }

      ws.onopen = () => {
        console.debug('Opening connection')
      }

      ws.onmessage = async (event: MessageEvent<string>) => {
        console.debug('Received message', event.data)

        const payload = JSON.parse(event.data) as { type: string; data: { dataUpdated: DataUpdatedTypes; data: unknown } }

        if (payload.type === 'pong') {
          return
        }

        if (payload.type === 'dataUpdated') {
          if (onMessage) {
            await onMessage(payload.data.dataUpdated, payload.data.data)
          }
        }
      }

      ws.onclose = (event: CloseEvent) => {
        window.clearInterval(keepAliveId as number)
        window.clearTimeout(reconnectId as number)

        if (ws && event.code === CLOSE_RECONNECT) {
          console.debug('Reconnecting')

          openConnection()
        }
      }

      keepAliveId = window.setInterval(() => {
        console.debug('Sending ping request')

        if (ws) {
          ws.send(JSON.stringify({ type: 'ping', token }))
        }
      }, 30000)

      reconnectId = window.setTimeout(() => {
        if (ws) {
          ws.close(CLOSE_RECONNECT)
        }
      }, 3600000)
    }

    openConnection()

    return Object.freeze({
      destroy: (): void => {
        console.debug('Closing connection')

        if (ws) {
          ws.close(CLOSE_DESTROY)
        }
      },
    })
  },
})

export default WebsocketService
